<template>
  <div class="elv-project-ended-container">
    <img src="@/assets/img/project/project-locked.png" alt="locked" />
    <h5>{{ props.isLocked ? t('title.projectEnded') : t('title.projectDeactivated') }}</h5>
    <p>{{ props.isLocked ? t('desc.projectLockedInfo') : t('desc.projectDeactivatedInfo') }}</p>
    <elv-button width="200px" height="45px" type="primary">
      <a href="mailto:sales@elven.com">{{ t('button.contactSales') }}</a>
    </elv-button>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  isLocked: {
    type: Boolean,
    default: true
  }
})

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.elv-project-ended-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 100%;

  img {
    width: 123px;
    height: 100px;
    display: block;
  }

  h5 {
    color: #0e0f11;
    text-align: center;
    font-family: 'Plus Jakarta Sans';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 30px;
    margin-bottom: 18px;
  }

  p {
    width: 410px;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 30px;
  }

  .elv-button {
    a {
      color: #fff;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
    }
  }
}
</style>
